.flex-to-grid-image-container {
    height: fit-content;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .flex-to-grid-item {
    width: 100%;
    max-width: 250px;
    border: none !important;
  }
  .flex-to-grid-item-0 {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .flex-to-grid-item-1 {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .flex-to-grid-item-2 {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .flex-to-grid-item-3 {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  
  @media screen and (min-width: 1100px) {
    .flex-to-grid-image-container {
      display: flex;
    }
  }