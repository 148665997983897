/* CustomSpices Section */

.custom-spices-container {
    width: 100%;
    height: fit-content;
    background-color: var(--primary-red);
    position: relative;
    top: calc(var(--header-top-height) + var(--header-bottom-height));
  
  }
  .custom-spices-text-container {
    height: fit-content;
    width: 100%;
    text-align: center;
    padding: 2rem 1rem;
  }
  .custom-spices-text-container > h2, .custom-spices-text-container > h3 {
      padding: 1rem 0rem;
  }
  .custom-spices-pic-container {
    height: fit-content;
    width: 100%;
    right: 0;
  }
  .custom-spices-pic-container > img {
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: 1100px) {
    .custom-spices-text-container, .custom-spices-pic-container {
        width: 50%;
    }
    .custom-spices-text-container {
        text-align: start;
        padding: 2rem;
    }
  }
  