/* Swiper section */

.swiper-container {
    width: 100%;
    height: calc(100vh - (var(--header-top-height) + var(--header-bottom-height)));
    top: calc(var(--header-top-height) + var(--header-bottom-height));
  }
  .swiper-slide {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: calc(100vh - (var(--header-top-height) + var(--header-bottom-height)));
  }
  .swiper-slide > img {
    height: 0;
    flex: 1 1 auto;
  }
  .slide-textbox {
    /* position: absolute; */
    position: relative;
    /* height: 30%;
    max-height: 30%; */
    width: 100%;
    max-width: unset;
    opacity: 1;
    height: fit-content;
    flex: 0 1 auto;
    /* padding-right: 2rem; */
    
  }
  .slide-textbox::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--primary-black);
  }
  .slide-textbox-item {
    width: 100%;
    z-index: 1;
    padding: 1rem;
    text-align: center;
  }
.slide-textbox-header {
    line-height: 2.8rem;
  }
  .slide-textbox-paragraph {
    line-height: 1.8rem;
  }
  .main-swiper-button-prev, .main-swiper-button-next {
    width: 50px;
    height: 50px;
    border: 3px solid white;
    border-radius: 50%;
    top: 85%;
  }
  .main-swiper-button-prev::after, .main-swiper-button-next::after {
    color: white;
    font-size: 30px;
    font-weight: bold;
  }
  .main-swiper-button-prev {
    left: 35%;
  }
  .main-swiper-button-next {
    right: 35%;
  }
  .swiper-pagination-horizontal {
    top: 82.5%;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background: var(--primary-white);
    opacity: 1;
    margin: 10px !important;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--secondary-red);
    border: 2px var(--primary-white) solid;
  }

  @media screen and (min-width: 1000px) {
    .swiper-slide > img {
      height: 100%;
    }
    .slide-textbox {
      position: absolute;
      height: fit-content;
      /* max-height: 350px; */
      width: 50vw;
      max-width: 650px;
    }
    .slide-textbox::before {
      opacity: 0.5;
    }
    .slide-textbox-item {
    width: 50%;
    margin: 1rem 3rem 1rem;
    z-index: 1;
    padding: 1rem;
    text-align: start;
  }
}