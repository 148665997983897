/* General Styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--primary-white);
  font-family: 'Roboto', sans-serif;
  line-height: 1.5rem;
}
:root {
  --primary-red: #A62120;
  --secondary-red: #B50014;
  --primary-black: #000000;
  --secondary-black: #252525;
  --primary-white: #ffffff;
  /* to take header into consideration when setting sections */
  --header-top-height: 100px;
  --header-bottom-height: 50px;
}
a {
  text-decoration: none;
  color: unset;
}
p {
  margin-top: 1rem;
}

/* Flex Declarations */

.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-c-s {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flex-c-e {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.flex-sa-s {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.flex-s-c {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-e-c {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-se-c {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.flex-sb-s {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-se-s {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.flex-se-stretch {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
}
.flex-stretch-s {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

/* Pointer on hover */

.p-on-h {
  cursor: pointer;
}

/* Logo Element */

.logo-large {
  height: 100%;
  width: 100%;
}
.logo-small {
  height: 80%;
  width: 80%;
}

.layout-helper-container {
  min-width: 300px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
