/* Footer Section */

.footer-container {
    width: 100%;
    /* max-width: 1000px; */
    height: fit-content;
    margin: 0 auto;
    background-color: var(--secondary-red);
    position: relative;
    top: calc(var(--header-top-height) + var(--header-bottom-height));
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
  
  }
  /* .footer-container::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--secondary-red);
    position: absolute;
  } */
  .footer-contacts-container {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .footer-contact > img, p {
    display: inline;
  }
  .footer-contact > img, .icon-placeholder {
    width: 10px;
    height: 10px;
  }
  .contact-text {
    margin: 0;
  }
  .footer-logo {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .social-media-container {
    flex-wrap: wrap;
    grid-row: 3/4;
    grid-column: 1/2;
  }
  .social-media-logo-btn {
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 50%;
  }

  @media screen and (min-width: 1000px) {
    .footer-container {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
    .footer-contacts-container {
      grid-row: 1/2;
      grid-column: 1/2;
    }
    .footer-logo {
      grid-row: 1/2;
      grid-column: 2/3;
    }
    .social-media-container {
      grid-row: 1/2;
      grid-column: 3/4;
    }
  }